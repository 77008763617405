export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "message_remove_confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить это сообщение?"])}
      },
      "en": {
        "message_remove_confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this message?"])}
      }
    }
  })
}
