export default {
  misc: {
    accounts: 'Accounts',
    new_payment_invoice: 'New invoice for payment',
    acquiring: 'Acquiring',
    briskly_catalog: 'Briskly catalog',
    buy_device: 'Buy device',
    catalogs_categories: 'Catalogs and categories',
    new_catalog: 'New catalog',
    new_category: 'New category',
    new_discount: 'New discount',
    discounts_on_goods: 'Discounts on goods',
    discounts_on_category: 'Discounts on category',
    discounts_on_order: 'Discounts on order',
    discounts_on_good: 'Discounts on good',
    adding_discount: 'Adding discount',
    acts: 'Acts',
    sign_document: 'Sign document',
    legal_entity_id: 'Legal entity ID :id',
    new_legal_entity: 'New legal entity',
    legal_entity: 'Legal entity',
    event_page: 'Event page',
    telemetry: 'Telemetry',
    telemetry_page: 'Telemetry page',
    questions_answers: 'Questions and answers',
    new_product: 'New product',
    good_page: 'Product page',
    mailings: 'Mailings',
    home: 'Home',
    updates: 'Updates',
    marketplace: 'Marketplace',
    warehouse: 'Warehouse',
    new_menu: 'New menu',
    menu_page: 'Menu page',
    operations: 'Operations',
    payments: 'Payments',
    charges: 'Payments',
    payment_id: 'Payment ID',
    return_processing: 'Return processing',
    main_information: 'Main information',
    returns: 'Returns',
    create_return: 'Create return',
    profile_settings: 'Profile settings',
    user_profile: 'User profile',
    compensations: 'Compensations',
    registration: 'Registration',
    stock: 'Stock',
    warehouse_stock: 'Warehouse / Stock',
    reports: 'Reports',
    marketing: 'Marketing',
    movement_reports: 'Movement reports',
    create_move_report: 'Create movement report',
    warehouse_reports: 'Warehouse / Movement reports',
    warehouse_report: 'Warehouse / Report :id',
    supply_id: 'Supply ID',
    new_supply: 'New supply',
    warehouse_supplies: 'Warehouse / Supplies',
    warehouse_supply: 'Warehouse / Supply',
    create_supply: 'Create supply',
    edit_supply: 'Edit supply',
    terminal_advert: 'Terminal advert',
    new_ad_block: 'New ad block',
    virtual_terminal_id: 'Virtual terminal :id',
    new_virtual_terminal: 'New virtual terminal',
    virtual_terminal: 'Virtual terminal',
    devices: 'Devices',
    new_trade_point: 'New trade point',
    catalog_binding: 'Catalog binding',
    adding_goods: 'Adding goods',
    integration: 'Integration',
    create_catalog: 'Create catalog',
    create_good: 'Create good',
    edit_trade_point: 'Edit trade point',
    create_trade_point: 'Create trade point',
    movement_id: 'Movement :id',
    new_movement: 'New movement',
    warehouse_transfers: 'Warehouse / Transfers',
    warehouse_transfer: 'Warehouse / Transfer',
    create_transfer: 'Create transfer',
    edit_movement: 'Edit movement',
    new_writeoff: 'New write-off',
    write_off: 'Write-off :id',
    warehouse_write_offs: 'Warehouse / Write-offs',
    write_offs_write_off: 'Write-offs / Write-off',
    create_write_off: 'Create write-off',
    edit_write_off: 'Edit write-off',
    not_taxable: 'Not taxable',
    settlement_5_105: 'Settlement 5/105',
    settlement_7_107: 'Settlement 7/107',
    settlement_10_110: 'Settlement 10/110',
    settlement_20_120: 'Settlement 20/120',
    no_preheating: 'No preheating',
    min_power: 'Minimum power',
    average_power: 'Average power',
    max_power: 'Maximum power',
    meter: 'm',
    minute: 'Minute',
    hour: 'Hour',
    day: 'Day',
    year: 'Year',
    self_service_store: 'Self-service store',
    micromarket: 'Micromarket',
    online_showcase: 'Online showcase',
    preorder: 'Preorder',
    production_delivery: 'Production with delivery',
    coffee_machine: 'Coffee machine',
    gas_station: 'Gas station',
    cigarette_cabinet: 'Cigarette cabinet',
    module: 'Module',
    instruction: 'Instruction',
    horizontal_instruction: 'Horizontal instruction',
    micromarket_templates: 'Micromarket templates',
    on_glass: 'On glass',
    side_stickers: 'Side stickers',
    to_advertising_block: 'To advertising block',
    r10ns_no_ad: 'R10NS (no advertising block)',
    r10nsg_with_ad: 'R10NSG (with advertising block)',
    desktop_banner: 'Desktop banner',
    goods_showcase_link: 'Goods showcase link',
    your_discount_name: 'Your discount name in the back office',
    user_view_brand: 'What the user will see in the % tab above the company brand',
    mon_wed_fri: 'Mon, Wed, Fri',
    description_more: 'Description...',
    minimum: 'Minimum',
    maximum: 'Maximum',
    goods_cart_movement: 'Goods movements in carts',
    t: 'T',
    month: 'Month',
    added: 'Added',
    subscribed: 'Subscribed',
    settlement_acts: 'Settlement acts',
    bind_to_trade_point: 'Bind to trade point',
    event: 'Event',
    in_30_minutes: 'In 30 minutes',
    in_1_minute: 'In 1 minute',
    export_all_goods: 'Export all goods',
    today: 'Today',
    view_only: 'View only',
    view_and_pay: 'View and pay',
    menu_name: 'Menu name',
    menu_id_or_name: 'Menu ID or name',
    trade_point_id_name: 'Trade point ID and name',
    catalog_id_and_name: 'Catalog ID and name',
    return_completed: 'Return completed!',
    payment_receipt: 'Payment receipt',
    export_payment: 'Export payment',
    trade_point_address: 'Trade point address',
    week: 'Week',
    counterparties: 'Counterparties',
    add_counterparty: 'Add counterparty',
    create_counterparty: 'Create new counterparty',
    dealer: 'Dealer',
    distributor: 'Distributor',
    manufacturer: 'Manufacturer',
    merchant: 'Merchant',
    service_company: 'Service company',
    sending: 'Sending',
    balance_acceptance: 'Balance acceptance',
    sending_details: 'Sending details',
    diagnostics: 'Diagnostics',
    report_number: 'Report №',
    report_name: 'Report name',
    export_report: 'Export report',
    delete_report: 'Delete report',
    update_success: 'Update successful',
    ad_block_name: 'Ad block name',
    create_terminal: 'Create terminal',
    terminal_details: 'Terminal details',
    terminal_trade_points: 'Terminal trade points',
    activation_error: 'Activation error',
    no_employees: 'No employees',
    no_catalog: 'No catalog',
    no_schedule: 'No schedule',
    changes_saved: 'Changes saved',
    enter_catalog_name: 'Enter catalog name',
    transfer_id: 'Transfer ID',
    from_where: 'From where',
    where_to: 'Where to',
    movement_number: 'Movement №',
    export_transfer: 'Export transfer',
    write_off_id: 'Write-off ID',
    write_off_status: 'Write-off status',
    export_write_off: 'Export write-off',
    store: 'Store',
    payment_name: 'Payment name',
    export_device: 'Export device',
    delete_device: 'Delete device',
    order_number: 'Order №',
    client: 'Client',
    goods_quantity: 'Goods quantity',
    model: 'Model',
    device_model: 'Device model',
    trade_point_id: 'Trade point ID',
    trade_point_name: 'Trade point name...',
    initial_balance: 'Initial balance',
    initial_amount: 'Initial amount',
    receipt: 'Receipt',
    expense: 'Expense',
    final_balance: 'Final balance',
    total_sum: 'Total sum',
    requisites_status: 'Requisites status',
    total: 'Total',
    date_required: 'Date required',
    time_required: 'Time required',
    completed_success: 'Completed successfully',
    catalog_no: 'Catalog №',
    delete_catalog: 'Delete catalog',
    export_catalog: 'Export catalog',
    category_no: 'Category №',
    activate_category: 'Activate category',
    deactivate_category: 'Deactivate category',
    delete_category: 'Delete category',
    move_to_catalog: 'Move to catalog',
    discount_percent: 'Discount percent',
    discount_rubles_fixed: 'Discount in rubles (fixed discount)',
    in_percentage: 'in percentage',
    in_currency: 'in currency',
    good_number: 'Good №',
    discount_no: 'Discount №',
    in_app: 'In app',
    barcode_invalid: 'Barcode does not meet the standard',
    standard: 'Standard',
    expiry_date: 'Expiry date',
    number_of_stickers: 'Number of stickers',
    total_price: 'Total price',
    show_7: 'Show 7',
    show_10: 'Show 10',
    show_25: 'Show 25',
    show_50: 'Show 50',
    order_name: 'Order name',
    order_composition: 'Order composition',
    order_date_time: 'Order date and time',
    payment_type: 'Payment type',
    commission: 'Commission',
    transaction_date: 'Transaction date',
    payment_order_id: 'Payment order ID',
    application: 'Application',
    return_status: 'Return status',
    delivery_type: 'Delivery type',
    delivery_price: 'Delivery price',
    delivery_cost: 'Delivery cost',
    discounted_amount: 'Discounted amount',
    promo_code_name: 'Promo code name',
    in_menu: 'In menu',
    online: 'Online',
    design: 'Design',
    personal_data: 'Personal data',
    sold: 'Sold',
    organization_tax_id: 'Organization tax ID',
    operation_time_zone: 'The operation time is specified in the time zone of the store.',
    transfer_operation_time: 'The time of the transaction is indicated according to the time zone of the trade point from which the goods are exported.',    order_id: 'Order ID',
    size_format: 'Size and format',
  }
};
