export default {
  common: {
    online_carts: 'Online-carts',
    settings: 'Settings',
    trade_point: 'Trade point',
    trade_points: 'Stores',
    category: 'Category',
    catalog: 'Catalog',
    catalogs: 'Catalogs',
    categories: 'Categories',
    discounts: 'Discounts',
    documents: 'Documents',
    employees: 'Employees',
    add_employee: 'Add employee',
    employee_page: 'Employee page',
    legal_entities: 'Legal entities',
    events: 'Events',
    goods: 'Goods',
    print_sticker_tag: 'Sticker and price tag printing',
    print_sticker: 'Sticker Printing',
    menu: 'Menu',
    payment: 'Payment',
    video: 'Video',
    promo_codes: 'Promo codes',
    delivery: 'delivery',
    virtual_terminals: 'Virtual terminals',
    details: 'Requisites',
    address: 'Address',
    administrator: 'Administrator',
    courier: 'Courier',
    checker: 'Checker',
    not_installed: 'Not set',
    error: 'Error!',
    success: 'Success',
    proceed: 'Go to',
    photo: 'Photo',
    barcode: 'Barcode',
    name: 'Name',
    price: 'Price',
    quantity: 'Quantity',
    discount: 'Discount',
    total_amount: 'Total amount',
    order: 'Order',
    add_discount: 'Add discount',
    month: 'month',
    half_year: 'Half a year',
    year: 'year',
    six_months: 'half a year',
    type: 'Type of',
    phone: 'Telephone',
    role: 'Role',
    delete_employee: 'Remove employee',
    attached_trade_points: 'Bound stores',
    cancel: 'Cancel',
    confirm: 'Confirm',
    data_not_found: 'No data with chosen parameters',
    change_filter_set: 'Please, change filter settings',
    delete_success: 'Deleted successfully',
    changes_saved_ok: 'Changes saved successfully',
    average: 'Average',
    save: 'Save',
    time: 'Time',
    good: 'Item',
    total: 'Total',
    payment_number: 'Payment No.',
    trade_point_id: 'Store ID',
    client_id: 'Client ID',
    creation_time: 'Time of creation',
    amount: 'Amount',
    status: 'Status',
    period: 'Period',
    order_status: 'Order status',
    measurement: 'Measurement',
    active: 'Active',
    inactive: 'Inactive',
    sticker: 'Sticker',
    good_id: 'Item ID',
    yes: 'Yes',
    no: 'No',
    catalog_name: 'Directory name',
    category_name: 'name of category',
    trade_point_id_name: 'ID or name of the trade point',
    all: 'Everything',
    connected: 'connected',
    disabled: 'Disabled',
    delete_good: 'Delete item',
    export_good: 'Export item',
    move_to_category: 'Move to a category',
    remove_from_category: 'Remove from category',
    filters: 'Filters',
    table_setup: 'Setting up a table',
    confirm_delete_good: 'Are you sure you want to delete item?',
    download_csv: 'Download .csv',
    download_xlsx: 'Download .xlsx',
    goods_list_empty: 'The list of your products is still empty.',
    you_can: 'You can',
    add_good: 'Add product',
    choose_period: 'choose period',
    choose_date: 'select date',
    base_catalog: 'Base catalog',
    clear_filters: 'Clear filters',
    creation_date: 'date of creation',
    micro_market_rating: 'Micromarket rating',
    last_name: 'Surname',
    time_zone: 'Timezone',
    undefined: 'All days',
    availability: 'Availability',
    product_name: 'Product Name',
    base_price: 'Base price',
    trade_point_name: 'Tradepoint name',
    reporting_period: 'Reporting period',
    group_by: 'Group by',
    no_data_parameters: 'No data on the specified parameters',
    pieces: 'pcs.',
    orders: 'Orders',
    unknown: 'Unknown',
    main: 'Basic',
    deliveries: 'Delivery',
    service_center: 'Service center',
    equipment: 'Equipment',
    delivery_date_time: 'Delivery date and time',
    goods_quantity: 'Quantity of goods',
    supply_status: 'Delivery status',
    operation_method: 'Method of operation',
    supply_number: 'Delivery No.',
    open: 'Open',
    completed: 'Completed',
    briskly_business: 'Briskley Business',
    export_supply: 'Export delivery',
    export: 'Export',
    supplies: 'Deliveries',
    seconds: 'sec',
    terminal: 'Terminal',
    creation_successful: 'Creation successful',
    date_time: 'date and time',
    movements: 'Movements',
    write_offs: 'Write-offs',
    writeoff: 'Write-off',
    min_size_format: 'Minimum size and format',
    upload: 'Upload',
    discount_parameters: 'Discount options',
    unit_of_measure: 'Unit of measurement',
    discount_size: 'Amount of discount',
    rounding_type: 'Rounding type',
    round_precision: 'Rounding Accuracy',
    days_of_week: 'Days of the week',
    daily: 'Daily',
    or: 'or',
    select_category: 'Select a category...',
    select_catalog: 'Select a directory',
    show_all: 'show all',
    discount_type: 'Discount type',
    y_plural: 's',
    select_date: 'Choose a date',
    choose: 'Select',
    responsible_person: 'Responsible',
    add: 'Add',
    write_off_reason: 'Reason for writing off',
    select_employee: 'Select an employee',
    employee_name: 'Employee name',
    select_trade_point: 'Select a retail outlet',
    edit: 'Edit',
    return_to_list: 'Back to the list',
    order_amount: 'Order price',
    goods_selected: 'Selected items',
    creation: 'Creation',
    completed_success: 'completed successfully',
    creation_error: 'Error in creating',
    editing: 'Editing',
    editing_error: 'Error during editing',
    no_catalog_trade_point: 'No selected catalog in store!',
    add_point: 'Add tradepoint',
    select_trade_points: 'Select outlets',
    apply: 'Apply',
    enter_title: 'Enter the name',
    to_list: 'To the list',
    validity_period: 'Time of action',
    catalogs_categories_empty: 'The list of your catalogs and categories is still empty.',
    and: 'and',
    age_limit: 'Age limit',
    add_device: 'Add device',
    save_completed_success: 'Saved successfully',
    enter_description: 'Enter a description',
    application_order: 'Application procedure',
    schedule: 'Schedule',
    catalog_selection: 'Directory selection',
    set_name: 'Set name',
    title: 'Title',
    good_photo: 'Product photo',
    total_discount: 'Total discount',
    first_purchase_no_time: 'First purchase without time consideration',
    first_purchase_limit: 'First purchase after the assigned time limit',
    full_account_access: 'Access to all the functionality of your personal account',
    micromarket_features: 'Available the ability to open a micromarket, change the product, take photos, upload photos to the courier interface',
    process_orders: 'Accepts orders from customers and collects them on the trading floor for shipment to the client',
    attention: '',
    skip: 'Skip',
    selected: 'Selected',
    tax_id: 'INN',
    bik: 'BIK',
    registration_number: 'OGRN',
    swift_bik: 'SWIFT / BIK',
    bank_name: 'Bank name',
    tax_id_or_name: 'TIN or name of a legal entity',
    continue: 'Proceed',
    minimum_length: 'Minimum length is',
    characters: 'symbols',
    organization_name: 'Name of the organization',
    tax_reason_code: 'KPP',
    legal_form_code: 'OKOPF',
    activity_code: 'OKVED',
    taxation_system: 'Taxation system',
    full_name: 'FULL NAME',
    passport: 'Passport',
    series: 'Series',
    issue_date: 'Issue date',
    division_code: 'Department code',
    date_of_birth: 'Date of birth',
    place_of_birth: 'Place of birth',
    citizenship: 'Citizenship',
    registration_address: 'Registration address',
    first_page: 'First page',
    registered_address: 'P. with registration',
    back: 'Back',
    not_filled: 'Not filled',
    patronymic: 'middle name',
    issued_by: 'Issued by',
    organization_data: 'Organization data',
    registered_capital: 'Authorized capital',
    organization_phone: 'Organization phone',
    offline: 'Offline',
    good_barcode: 'SK of goods',
    active_good: 'Active item',
    fill_barcode: 'Fill in the barcode ...',
    select_option: 'Select ...',
    enter_value: 'Enter value',
    upload_good_photo: 'Uploading a product photo',
    main_data: 'Basic data',
    create: 'Create',
    create_catalog: 'Create directory',
    currency: 'Currency',
    select_currency: 'Select currency',
    prefix_product_info: 'System will identify weight item by highlighting the digital code and its weight in grams by using this prefix. Availible prefixes are from 20 to 29. If there is no prefix, items will be considered as singles',
    weight_good_barcodes: 'Weight item barcode prefixes',
    enter_name: 'Enter the name',
    delete: 'Delete',
    select_column: 'Select a column ...',
    generate_valid_barcode: 'Generate a valid CC automatically',
    errors_found: 'Found errors',
    learn_more: 'More details',
    file_uploaded_success: 'File successfully uploaded',
    manufacture_date: 'Manufacturing date',
    start: 'Start',
    total_shelf_life: 'General shelf life',
    sticker_quantity: 'Number of stickers',
    enter_quantity: 'Enter quantity...',
    for_single_item: 'For one position',
    saved_successfully: 'Successfully saved',
    revenue: 'Revenue',
    average_check: 'Average receipt',
    menu_updated: 'Menu updated',
    refund_amount: 'Refund amount',
    trade_point_number: 'Store №.',
    serial_number: 'Serial number',
    production_date: 'Manufacture date',
    location: 'Location',
    shipping_date: 'Shipment date',
    sender: 'Sender',
    arrival_date: 'Receipt date',
    trade_point_supply: 'Point of sale',
    supply_composition: 'Scope of delivery',
    writeoff_date_time: 'Date and time of debiting',
    promo_materials: 'Promo materials',
    from_trade_point: 'From a point of sale',
    to_trade_point: 'To the point of sale',
    transfer_composition: 'Travel composition',
    trade_point_write_off: 'Write-off point of sale',
    write_off_composition: 'Write-off composition',
    end: 'End',
    completion: 'Completion',
    return_back: 'Back',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    role_in_company: 'Role in the company',
    current_quantity: 'Current quantity',
    trade_point_type: 'Tradepoint type',
    export_trade_points: 'Outlets export',
    delete_trade_point: 'Delete sales outlet',
    activity: 'Activity',
    return_to_discounts: 'Back to discounts',
    create_discount_done: 'Discount save finished',
    usage_features: 'Application features',
    discount_empty: 'Discounts section is empty',
    category_goods_discounts: 'There will be all discounts for items and item categories configurated during their creation and editing',
    create_new: 'create a new',
    attach_discount_to_item: 'and bind it to any item or item category.',
    you_can_also: 'Also you can',
    create_discount_set: 'create a set of discounts',
    order_discounts: ', it\'s a number of discounts which you can bind to whole order or multiple stores by chosen parameters.',
    delete_discount: 'Removing discount',
    discount_deleted: 'Discount removed!',
    discount_status: 'Discount status',
    enter_address: 'Enter the address ...',
    auto_generate_reports: 'Three types of reports are automatically generated',
    recipient: 'Recipient',
    equipment_transfer_reason: 'Reason for equipment transfer',
    being_delivered: 'In delivery',
    accept_to_balance: 'Accept to balance',
    process_return: 'Make a return',
    total_devices: 'Total devices',
    floor: 'Floor',
    finish: 'To complete',
    next: 'Next',
    generate_report: 'Generate a report',
    setup_integration: 'Set up integration',
    prefix_weight_good: 'System will identify weight item by highlighting the digital code and its weight in grams by using this prefix',
    available_prefixes: 'Availible prefixes are from 20 to 29',
    process_goods_individual: 'If there is no prefix, items will be considered as singles',
    good_status: 'Item status',
    deactivated: 'deactivated',
    blocked: 'locked',
    enter_trade_point_name: 'Enter the name of the outlet ...',
    trade_point_phone: 'Point of sale phone',
    your_logo: 'Your logo',
    set_market_schedule: 'Set a schedule for your micromarket: at what time it opens for purchases, and at what time it is blocked.',
    all_day: 'Around the clock',
    day_off: 'Day off',
    assign: 'Assign',
    select_trade_logo_appearance: 'Choose how your store logo will look in the B-Pay app.',
    display_in_product_card: 'It will be displayed in the product card.',
    number: 'Number...',
    closed_territory: 'Closed area',
    go_back: 'Return',
    no_data: 'No data',
    city: 'City',
    district: 'District',
    cost: 'Cost',
    to_category: 'To category',
    from_category: 'From category',
    information: 'Information',
    humanDateTimeAt: 'MM.dd.yyyy \'at\' HH:mm',
    validation: {
      required: 'Required field',
      min_length: 'Minimum length is {0} characters',
      max_length: 'Maximum length is {0} characters',
      invalid_numeric: 'Invalid characters',
      invalid_email: 'Invalid email',
      invalid_phone: 'Invalid phone number',
      invalid_characters: 'Input contains unacceptable symbols',
      invalid_uuid_format: 'Invalid UUID format',
      invalid_number: 'Invalid number',
      invalid_format: 'Invalid format',
      invalid_date: 'Invalid date',
    },
  }
};
